import React, { useContext, useEffect } from "react"
import styled, { css } from "styled-components"
import { media } from "../styles/media"
import { useInView } from "react-intersection-observer"
import { HeaderOverWrappedContext } from "../hooks/HeaderOverWrappedContext"

const StyledWrapper = styled.div`
  width:100%;
  background:${({ theme }) => theme.colors.light.brown};
  display:flex;
  flex-flow:column nowrap;
  justify-content:center;
  align-items:center;
  padding:80px 20% 50px;
  h3{
    color:${({ theme }) => theme.colors.light.brown};
    text-shadow:0px 0px 3px ${({ theme }) => theme.colors.primary.brown};
  }
  h2{
    font-size:1.62em;
    color:${({ theme }) => theme.colors.primary.brown};
    padding:.62em;
  }
  p{
    font-size:.9em;
    padding-bottom:30px;
  }
  ul li{
    padding:5px 0;
    list-style:square;
    margin-left:1em;
    font-size:.9em;
  }
  ${() => media.sp(css`
    padding:80px 5% 30px;
    h2{
      font-size:1.32em;
    }
    p{
      font-size:.8em;
    }
    ul li{
      font-size:.8em;
    }

  `
)}
`

const HeaderNonVisual = props => {
  const [state, setState] = useContext(HeaderOverWrappedContext)
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0.5,
  })

  useEffect(() => {
    setState(state => ({ ...state, isDisplay: !inView }))
  }, [inView])
  return (
    <StyledWrapper ref={ref}>
      <h3>{props.textEn}</h3>
      <h2>{props.textJp}</h2>
      <p dangerouslySetInnerHTML={{ __html: props.textMain }} />
      {props.textDetails && (
        <div dangerouslySetInnerHTML={{ __html: props.textDetails }} />
      )}
    </StyledWrapper>
  )
}

export default HeaderNonVisual
