import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import SEO from "../components/SEO"
import HeaderNonVisual from "../components/HeaderNonVisual"
import Layout from "../components/Layout"

const Audi = styled.div`
  padding: 50px 5% 100px;
  h3 {
    font-size: 1.32em;
  }
  p {
    padding: 15px 0;
    font-size: 0.9em;
  }
  .audiQ7 {
    padding: 15px 0;
    h4 {
      font-weight: bold;
    }
    p {
      font-size: 0.85em;
      a {
        font-size: 1em;
        color: red;
        padding: 0 0.2em;
        text-decoration: underline;
      }
    }
  }
`

const AboutThisSite = () => {
  return (
    <Layout>
      <SEO>
        <title>このサイトについて｜フランソア</title>
        <meta name="description" content="		 	 		このサイトについて 		フ..." />
      </SEO>
      <HeaderNonVisual
        textEn="About Our WebSite"
        textJp="このサイトについて"
        textMain="当サイトにおける免責事項や
知的財産権などについてお伝えします。"
      />
      <Audi>
        <h3>このサイトについて</h3>
        <p>
          フランソアウェブサイト<Link to="/">https://www.francois.co.jp/</Link>
          （以下、本ウェブサイト）は、株式会社フランソア（以下、当社）にて運営されております。このページでは、本ウェブサイトを利用いただく際に、予めご理解いただきたいことを記載しています。
        </p>
        <div className="audiQ7">
          <h4>免責事項について</h4>
          <p>
            当社は、本ウェブサイト上のコンテンツの内容について、妥当性や正確性について保証するものではなく、一切の責任を負い兼ねます。
            <br />
            本ウェブサイト上のコンテンツやURLは、予告なしに変更または中止されることがあります。あらかじめご了承願います。
            <br />
            理由の如何に関わらず、情報の変更及び本ウェブサイトの運用の中断または中止によって生じるいかなる損害についても責任を負うものではありません。
          </p>
        </div>
        <div className="audiQ7">
          <h4>著作権について</h4>
          <p>
            本ウェブサイト内のコンテンツ（文章・資料・画像・動画等）
            の著作権は、当社、または第三者が保有します。営利、非営利を問わず、本ウェブサイトのコンテンツを許可なく複製、転用、販売など二次利用することを禁じます。
          </p>
        </div>
        <div className="audiQ7">
          <h4>商標権について</h4>
          <p>
            本ウェブサイト内で使用される商号、商標、標章、ロゴマークなどに関する権利は、当社、関連会社または第三者が保有し、商標法、不正競争防止法、商法及びその他の法律で保護されています。これらを各々の権利者の許諾を得ることなく無断で使用などすることはできません。
          </p>
        </div>
        <div className="audiQ7">
          <h4>本ウェブサイトへのリンクについて</h4>
          <p>
            営利、非営利を問わず、このページの記載事項に同意頂ければ、リンクしていただけます。
            <br />
            ただし、リンクにより発生したトラブルや損害賠償問題等につき、当社は何ら責任を負いません。リンクされた方の責任で処理してください。なお、以下に該当するウェブサイトからのリンクはお断りいたします。
          </p>
          <ul>
            <li>
              当社または関連会社、それらの役員または社員を誹謗・中傷するウェブサイト
            </li>
            <li>
              当社または関連会社の製品・サービスを誹謗・中傷するウェブサイト
            </li>
            <li>違法である可能性のある情報を提供するウェブサイト</li>
            <li>
              猥褻あるいは社会倫理に反する可能性のある情報を提供するウェブサイト
            </li>
            <li>
              独自のフレーム内に本ウェブサイトを取り込んだ形など、当社ウェブサイトであることが不明確となるウェブサイト
            </li>
          </ul>
        </div>
        <div className="audiQ7">
          <h4>プライバシーポリシーについて</h4>
          <p>
            当社は、個人情報の重要性に鑑み、プライバシーポリシーを制定しております。
            <br />
            詳しくは、当社の
            <Link to="/privacy-policy/">プライバシーポリシー</Link>
            をご覧下さい。
          </p>
        </div>
      </Audi>
    </Layout>
  )
}

export default AboutThisSite
